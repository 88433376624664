import React from 'react'
import PropTypes from 'prop-types'
import {
  Heading as BaseHeading,
  ActiveLink as BaseLink
} from '@bonobolabs/mds-web-ui-reach'
import { Flex, Box, Divider } from 'rebass'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import Logo from './Logo'

const Container = Box

const Heading = styled(BaseHeading).attrs({
  mb: 3,
  fontSize: 0,
  color: 'darkbluegray'
})({ fontFamily: 'inherit' }, color, space)

const Link = props => {
  return <BaseLink fontSize={0} color="bluegray" {...props} />
}

const Section = ({ heading, children }) => (
  <Box>
    <Heading>{heading}</Heading>
    <div>{children}</div>
  </Box>
)

const FooterLinks = ({
  accountsExternal,
  accountsURL,
  homeExternal,
  homeURL
}) => {
  return (
    <Box
      css={{
        display: 'grid',
        gridRowGap: 12,
        gridColumnGap: 8,
        gridTemplateColumns: 'repeat(auto-fit, minmax(125px, 1fr))',
        gridAutoRows: 'auto'
      }}
    >
      <Section heading="Apps">
        <Link external={homeExternal} to={`${homeURL}/actions`}>
          Actions
        </Link>
        <Link external={homeExternal} to={`${homeURL}/flow`}>
          Flow
        </Link>
        <Link external={homeExternal} to={`${homeURL}/timepage`}>
          Timepage
        </Link>
        <Link external={homeExternal} to={`${homeURL}/overlap`}>
          Overlap
        </Link>
      </Section>
      <Section heading="Support">
        <Link external={homeExternal} to={`${homeURL}/support`} exact>
          Support
        </Link>
        <Link external={homeExternal} to={`${homeURL}/support/actions`}>
          Actions User Guide
        </Link>
        <Link external={homeExternal} to={`${homeURL}/support/flow`}>
          Flow User Guide
        </Link>
        <Link external={homeExternal} to={`${homeURL}/support/overlap`}>
          Overlap User Guide
        </Link>
        <Link external={homeExternal} to={`${homeURL}/support/timepage`}>
          Timepage User Guide
        </Link>
        <Link external={homeExternal} to={`${homeURL}/support/membership`}>
          Membership FAQ
        </Link>
        {false && (
          <Link external={homeExternal} to={`${homeURL}/status`}>
            System Status
          </Link>
        )}
        {false && (
          <Link external={homeExternal} to={`${homeURL}/contact`}>
            Contact Us
          </Link>
        )}
      </Section>
      <Section heading="Account">
        <Link external={accountsExternal} to={`${accountsURL}/account`}>
          Manage Account
        </Link>
        <Link external={accountsExternal} to={`${accountsURL}/login`}>
          Create Account
        </Link>
      </Section>
      <Section heading="Community">
        <Link external={accountsExternal} to={`${accountsURL}/feedback`}>
          Vote for Features
        </Link>
        <Link external to="https://www.instagram.com/bonobo" newTab>
          Instagram
        </Link>
        {false && (
          <Link
            external
            to="https://medium.com/moleskine-digital-studio"
            newTab
          >
            Medium
          </Link>
        )}
        {false && (
          <Link external={homeExternal} to={`${homeURL}/whats-new`}>
            What&apos;s New
          </Link>
        )}
        {false && (
          <Link external={homeExternal} to={`${homeURL}/newsletter`}>
            Newsletter
          </Link>
        )}
        {false && (
          <Link external={homeExternal} to={`${homeURL}/beta`}>
            Beta Program
          </Link>
        )}
      </Section>
      <Section heading="Bonobo">
        {false && (
          <Link external={homeExternal} to={`${homeURL}/about`}>
            About
          </Link>
        )}
        <Link external to={homeURL} newTab>
          Home
        </Link>
        <Link external={homeExternal} to={`${homeURL}/press`}>
          Press
        </Link>
        <Link external={homeExternal} to={`${homeURL}/contact`}>
          Contact Us
        </Link>
      </Section>
    </Box>
  )
}

const Text = styled('span')``
const Bullet = () => <Text color="bluegray">&nbsp;&bull;&nbsp;</Text>

const DisplayFlex = styled(Flex).attrs({
  display: ['block', 'flex']
})``

const FooterBottom = ({ homeExternal, homeURL, children }) => (
  <DisplayFlex alignItems="center" mt={[4, 3]}>
    {children}
    <Box mx="auto" />
    <Flex alignItems="center" mt={[4, 0]}>
      <Link
        external={homeExternal}
        to={`${homeURL}/privacy`}
        mr={2}
        lineHeight={1}
        color="bluegray"
      >
        Privacy & Terms
      </Link>
      {false && <Bullet />}
      {false && (
        <Link to="/sitemap" ml={2} lineHeight={1} color="bluegray">
          Sitemap
        </Link>
      )}
    </Flex>
  </DisplayFlex>
)

const CopyrightText = styled('div')`
  ${color};
  ${space};
  font-size: 10px;
`

const Copyright = ({ copyright = 'Bonobo Pte Lte' }) => (
  <CopyrightText color="bluegray" mt={[1, 3]}>
    {/* This has full browser compatability but does require javascript to work
          See https://github.com/gatsbyjs/gatsby/pull/10759/files for agreed-upon solution */}
    Copyright © {new Date().getFullYear()} {copyright}
    <Bullet />
    <Box css={{ display: 'inline-block', fontSize: 'inherit' }}>
      Made with ♥ by&nbsp;
      <Link
        external
        to="https://bonobolabs.com"
        fontSize="inherit"
        inline
        newTab
      >
        Bonobo{' '}
        <span role="img" aria-label="Bonobo">
          🦍
        </span>
      </Link>
    </Box>
  </CopyrightText>
)

export const Footer = ({
  bg = 'sidebarblue',
  maxWidth = 900,
  currentSite,
  accountsURL = 'https://accounts.bonobolabs.com',
  homeURL = 'https://bonobolabs.com',
  organisation,
  children,
  py = [4, 5],
  px = [4, 5],
  ...props
}) => {
  // TODO find a nicer way than this
  let accountsExternal = true
  let homeExternal = true

  if (currentSite === 'home') {
    homeExternal = false
    homeURL = ''
  }
  if (currentSite === 'accounts') {
    accountsExternal = false
    accountsURL = ''
  }

  const linksData = {
    accountsExternal,
    accountsURL,
    homeExternal,
    homeURL
  }

  return (
    <Box as="footer" bg={bg}>
      <Container {...props} maxWidth={maxWidth} px={px} py={py}>
        <FooterLinks
          currentSite={currentSite}
          {...linksData}
          organisation={organisation}
        />
        <Divider borderBottom="2px solid" borderColor="lightgreyborder" />
        <FooterBottom {...linksData}>{children || <Logo />}</FooterBottom>
        <Copyright copyright={organisation ? organisation.name : null} />
      </Container>
    </Box>
  )
}

Footer.propTypes = {
  bg: PropTypes.string,
  maxWidth: PropTypes.number,
  currentSite: PropTypes.oneOf([null, 'accounts', 'home', 'actions'])
}
